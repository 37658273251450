import { selectCurrentLanguageIsoCode } from "features/language/selectors/language-selector";
import { useScriptLoader } from "hooks/use-script-loader";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  addCustomEventListener,
  CUSTOM_EVENTS,
  removeCustomEventListener
} from "./cultura-guides-collaborators";
import {
  handleBackEvent,
  handleDropdownEvent,
  handleNext,
  handlePrevious,
  handleSearch,
  handleSelectGuide,
  handleTableOfContent
} from "./cultura-guides-event-handlers";

export const useCulturaGuides = () => {
  const languagueCode = useSelector(selectCurrentLanguageIsoCode);
  const { isLoaded } = useScriptLoader(
    process.env.REACT_APP_CULTURA_GUIDES_SCRIPT_URL || ""
  );

  useEffect(() => {
    addCustomEventListener(CUSTOM_EVENTS.FILTER_SEARCH, handleSearch);
    addCustomEventListener(
      CUSTOM_EVENTS.SELECT_USING_MOBILE_DROPDOWN,
      handleDropdownEvent
    );
    addCustomEventListener(CUSTOM_EVENTS.PREVIOUS, handlePrevious);
    addCustomEventListener(CUSTOM_EVENTS.NEXT, handleNext);
    addCustomEventListener(
      CUSTOM_EVENTS.TABLE_OF_CONTENT,
      handleTableOfContent
    );
    addCustomEventListener(CUSTOM_EVENTS.SELECT_GUIDE, handleSelectGuide);
    addCustomEventListener(CUSTOM_EVENTS.BACK_TO_GUIDES, handleBackEvent);

    return () => {
      removeCustomEventListener(CUSTOM_EVENTS.FILTER_SEARCH, handleSearch);
      removeCustomEventListener(
        CUSTOM_EVENTS.SELECT_USING_MOBILE_DROPDOWN,
        handleDropdownEvent
      );
      removeCustomEventListener(CUSTOM_EVENTS.PREVIOUS, handlePrevious);
      removeCustomEventListener(CUSTOM_EVENTS.NEXT, handleNext);
      removeCustomEventListener(
        CUSTOM_EVENTS.TABLE_OF_CONTENT,
        handleTableOfContent
      );
      removeCustomEventListener(CUSTOM_EVENTS.SELECT_GUIDE, handleSelectGuide);
      removeCustomEventListener(CUSTOM_EVENTS.BACK_TO_GUIDES, handleBackEvent);
    };
  }, []);

  return {
    isLoaded,
    languagueCode,
    brandId: process.env.REACT_APP_CULTURA_GO_BRAND_ID
  };
};
