import { appLoadedReducer } from "features/loading/loading-reducer";
import { configurationReducer } from "features/configuration/reducers/configuration-reducer";
import { combineReducers } from "redux";
import { setAssistanceServiceSelectedReducer } from "features/assistance-section/assistance-section-reducer";
import { currentLanguageReducer } from "features/language/reducers/language-reducer";
import { toasterReducer } from "features/toaster";
import { menuReducer } from "features/hamburguer-menu/reducers/menu-reducer";
import { requireLoginReducer } from "features/require-login/require-login-reducer";
import { installationModalDisplayedOnFirstVisitReducer } from "features/installation-modal/reducer/installation-modal-displayed-on-first-visit";
import { installationModalReducer } from "features/installation-modal/reducer/installation-modal-reducer";
import { globalPartnerParametersReducer } from "features/global-partner-parameters/reducers/global-partner-parameters-reducer";
import { debugModeReducer } from "features/debug-mode";
import { urlParametersReducer } from "features/url-parameters";

const reducers = combineReducers({
  isAppLoaded: appLoadedReducer,
  configuration: configurationReducer,
  assistanceServiceSelected: setAssistanceServiceSelectedReducer,
  currentLanguage: currentLanguageReducer,
  toaster: toasterReducer,
  isMenuOpen: menuReducer,
  requireLogin: requireLoginReducer,
  installationModalDisplayedOnFirstVisit: installationModalDisplayedOnFirstVisitReducer,
  installationModal: installationModalReducer,
  globalPartnerParameters: globalPartnerParametersReducer,
  urlParameters: urlParametersReducer,
  debugMode: debugModeReducer
});

export default reducers;
