import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

const timelineContainerMobile = css`
  padding: 0rem;
`;

export const TimelineContainer = styled.div`
  margin-right: auto;
  max-width: 640px;
  padding: 0 1.5rem 0 0;
  ${getCssForMobileView(timelineContainerMobile)}
`;

export const TimelineList = styled.ol<any>`
  list-style: none;
  margin: 0;
  padding: 0;
  counter-reset: numbered-list;
  margin-left: 10px;
  position: relative;
`;

const timelineItemMobile = css`
  width: 31px;
  height: 31px;
  line-height: 31px;
  left: -15px;
`;

export const TimelineItem = styled.li<any>`
  font-size: 16px;
  line-height: 1.2;
  padding: 0 38px 38px;
  border-left: 2px solid #ffffff;
  &:last-child {
    margin-left: -2px;
    margin-right: -2px;
    border-left: unset;
    padding-bottom: 0;
  }

  &:before {
    background-color: ${({ theme }) => theme.white};
    border-radius: 50%;
    color: ${({ theme }) => theme.secondaryColor["300"]};
    content: counter(numbered-list, decimal);
    counter-increment: numbered-list;
    display: block;
    font-weight: bold;
    width: 38px;
    height: 38px;
    margin-top: -0.5em;
    line-height: 38px;
    position: absolute;
    left: -18px;
    right: -20px;
    text-align: center;
    ${getCssForMobileView(timelineItemMobile)}
  }
`;

const timelineItemTitleMobile = css`
  font-size: 16px;
  line-height: 24px;
`;

export const TimelineItemTitle = styled.h4`
  font-family: ${({ theme }) => theme.secondaryFontFamily};
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  ${getCssForMobileView(timelineItemTitleMobile)}
`;

const timelineItemDescriptionMobile = css`
  font-size: 14px;
  line-height: 21px;
`;

export const TimelineItemDescription = styled.p`
  margin-top: 10px;
  font-family: ${({ theme }) => theme.secondaryFontFamily};
  font-size: 18px;
  line-height: 27px;
  ${getCssForMobileView(timelineItemDescriptionMobile)}
`;
