import React from "react";
import { withTheme } from "styled-components";
import { IconProps } from "./icon-types";

const PersonIcon = ({ theme }: IconProps) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0051 4.99523C12.0051 7.20714 10.212 9.00023 8.00012 9.00023C5.78822 9.00023 3.99512 7.20714 3.99512 4.99523C3.99512 2.78333 5.78822 0.990234 8.00012 0.990234C10.212 0.990234 12.0051 2.78333 12.0051 4.99523ZM0.00488281 15.0002C0.00488281 12.3302 5.32988 10.9952 7.99988 10.9952C10.6699 10.9952 15.9949 12.3452 15.9949 15.0002V16.9952H0.00488281V15.0002Z"
        fill={theme.primaryColor["300"]}
      />
    </svg>
  );
};

PersonIcon.defaultProps = {
  theme: {
    primaryColor: "#00008F"
  }
};
export default withTheme(PersonIcon);
