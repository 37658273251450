import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

const AssistanceContainerMobileStyles = css`
  flex-direction: column;
  order: unset;
  background-color: transparent;
  padding: 0px;
`;

export const SubsectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.grey["200"]};
  flex-basis: 100%;
  padding: 40px 40px 56px 40px;
  order: 4;
  ${getCssForMobileView(AssistanceContainerMobileStyles)}
`;
