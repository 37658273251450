import ArrowDownIcon from "../icons/arrow-down-icon";
import WorldIcon from "../icons/world-icon";
import styled, { css } from "styled-components";
import Select from "react-select";
import { getCssForMobileView } from "styles/responsive";

export const MainLanguagesSelectContainer = styled.button`
  display: flex;
  align-items: center;
`;

export const LanguageLabelAndIcon = styled.div`
  display: flex;
  align-items: center;
`;

const SelectLanguageMobile = getCssForMobileView(css`
  border-left: initial;
  justify-content: flex-end;
  width: unset;
`);

export const SelectLanguage = styled(Select).attrs(() => ({
  role: "button"
}))`
  display: flex;
  justify-content: space-between;
  margin-inline-end: 0px;
  width: 220px;
  align-self: center;
  cursor: pointer;
  ${SelectLanguageMobile}
`;

export const WorldIconContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const CustomWorldIcon = styled(WorldIcon)`
  transform: translate(0px, -1px);
  margin: 4px 8px 0;
  path {
    fill: ${({ theme }) => theme.grey["700"]};
  }
`;

export const CustomDownArrow = styled(ArrowDownIcon)`
  cursor: pointer;
  margin-right: 17px;
`;

export const Separator = styled.div`
  height: 25px;
  width: 2px;
  background-color: ${({ theme }) => theme.grey["400"]};
`;

export const MobileLanguageSelectorContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.grey["100"]};
  height: 40px;
  border-bottom: 1px solid #cccccc;
  position: fixed;
  top: 0;
  z-index: 2000;
`;
export const getSelectStyles = (theme: any) => ({
  control: (base: any) => ({
    ...base,
    border: 0,
    minWidth: "110px",
    width: "100%",
    height: "40px",
    appearance: "none",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    boxSizing: "border-box",
    overflowY: "none",
    backgroundColor: "inherit",
    cursor: "pointer",
    "@media (max-width: 600px)": {
      width: "initial"
    }
  }),
  menuList: (styles: any) => ({
    ...styles,
    paddingTop: "0",
    "@media (max-width: 600px)": {
      paddingBottom: "0",
      borderBottom: `1px solid ${theme.grey["100"]}`
    }
  }),
  menu: (styles: any) => ({
    ...styles,
    zIndex: 2000,
    marginTop: "1px",
    borderRadius: 0,
    boxShadow: 0,
    "@media (max-width: 680px)": {
      ...styles["@media (max-width: 680px)"],
      marginTop: 0
    }
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    padding: "2px 8px 2px 0"
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: theme.grey["700"],
    fontSize: "16px",
    fontFamily: theme.secondaryFontFamily,
    textTransform: "uppercase",
    fontWeight: "600",
    "@media (max-width: 600px)": {
      ...provided["@media (max-width: 600px)"]
    }
  }),
  option: (styles: any, state: any) => ({
    ...styles,
    cursor: "pointer",
    display: "flex",
    height: "62px",
    alignItems: "center",
    padding: "8px 24px",
    color: state.isSelected ? theme.primaryColor["200"] : theme.grey["700"],
    fontFamily: theme.secondaryFontFamily,
    fontSize: "16px !important",
    lineHeight: "24px",
    fontWeight: state.isSelected ? "600" : "400",
    backgroundColor: state.isFocused ? theme.grey["100"] : theme.white
  })
});
