import React from "react";
import { withTheme } from "styled-components";
import { IconProps } from "./icon-types";

const LoginIcon = ({ theme, inverted, ...props }: IconProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="login-icon"
      {...props}
    >
      <path
        d="M7.00004 11.8002C5.33337 11.8002 3.86004 10.9468 3.00004 9.66683C3.02004 8.3335 5.66671 7.60016 7.00004 7.60016C8.33337 7.60016 10.98 8.3335 11 9.66683C10.14 10.9468 8.66671 11.8002 7.00004 11.8002ZM7.00004 2.3335C7.53047 2.3335 8.03918 2.54421 8.41425 2.91928C8.78933 3.29436 9.00004 3.80306 9.00004 4.3335C9.00004 4.86393 8.78933 5.37264 8.41425 5.74771C8.03918 6.12278 7.53047 6.3335 7.00004 6.3335C6.46961 6.3335 5.9609 6.12278 5.58583 5.74771C5.21075 5.37264 5.00004 4.86393 5.00004 4.3335C5.00004 3.80306 5.21075 3.29436 5.58583 2.91928C5.9609 2.54421 6.46961 2.3335 7.00004 2.3335ZM7.00004 0.333496C6.12456 0.333496 5.25765 0.505935 4.44882 0.840966C3.63998 1.176 2.90505 1.66706 2.286 2.28612C1.03575 3.53636 0.333374 5.23205 0.333374 7.00016C0.333374 8.76827 1.03575 10.464 2.286 11.7142C2.90505 12.3333 3.63998 12.8243 4.44882 13.1594C5.25765 13.4944 6.12456 13.6668 7.00004 13.6668C8.76815 13.6668 10.4638 12.9644 11.7141 11.7142C12.9643 10.464 13.6667 8.76827 13.6667 7.00016C13.6667 3.3135 10.6667 0.333496 7.00004 0.333496Z"
        fill={inverted ? theme.white : theme.primaryColor["300"]}
      />
    </svg>
  );
};

export default withTheme(LoginIcon);
