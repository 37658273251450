import { State } from "app/store/types";

export const selectGlobalCountry = (state: State) =>
  state.globalPartnerParameters.country;

export const selectGlobalPartnerId = (state: State) =>
  state.globalPartnerParameters.partnerId;

export const selectGlobalPartnerName = (state: State) =>
  state.globalPartnerParameters.partnerName;
