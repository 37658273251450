import React from "react";
import { withTheme } from "styled-components";
import { IconProps } from "./icon-types";

const CrossIcon = ({ theme, ...props }: IconProps) => {
  return (
    <svg
      name="cross-icon"
      {...props}
      width="24"
      height="32"
      viewBox="0 0 24 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.3933 8.54675L22.5831 6.66675L15.4064 14.1201L8.22966 6.66675L6.41943 8.54675L13.5962 16.0001L6.41943 23.4534L8.22966 25.3334L15.4064 17.8801L22.5831 25.3334L24.3933 23.4534L17.2166 16.0001L24.3933 8.54675Z"
        fill={theme.primaryColor["300"]}
      />
    </svg>
  );
};

CrossIcon.defaultProps = {
  theme: {
    primaryColor: "#000072"
  }
};

export default withTheme(CrossIcon);
