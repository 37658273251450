import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

const TipsNavMobile = css`
  margin-top: 70px;
  margin-bottom: 0px;
`;

export const TipsNav = styled.div`
  position: sticky;
  top: -1px;
  background: ${({ theme }) => theme.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  overflow-x: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  ${getCssForMobileView(TipsNavMobile)}
`;

const TipsTabMobile = css`
  padding: 15px 16px;
  flex: 1 0 150px;
`;

export const TipsTab = styled.div<{ selected: boolean }>`
  flex-grow: 1;
  height: 65px;
  padding: 24px 16px;
  text-align: center;
  text-transform: uppercase;
  word-break: break-word;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryColor["300"]};
  border-bottom: ${({ theme, selected }) =>
    selected ? `3px solid ${theme.tertiaryColor}` : "none"};
  ${getCssForMobileView(TipsTabMobile)};
`;

export const TipsSectionHeader = styled.h3`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  color: ${({ theme }) => theme.dark};
  font-weight: bold;
  font-size: 36px;
  line-height: 46px;
  padding-top: 16px;
  margin-bottom: 32px;
  scroll-margin-top: 75px;
`;

const TipMobile = css`
  flex-direction: column;
`;

export const Tip = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 42px;
  ${getCssForMobileView(TipMobile)}
`;

const TipTitleMobile = css`
  font-size: 20px;
`;

export const TipTitle = styled.h4`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 10px;
  ${getCssForMobileView(TipTitleMobile)}
`;

export const TipDescription = styled.div``;

export const CheckIconStyled = styled.div`
  margin-right: 16px;
`;

const DownloadButtonMobile = css`
  width: 100%;
  justify-content: center;
`;

export const DownloadButton = styled.button`
  display: flex;
  width: inherit;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.primaryColor["300"]};
  color: ${({ theme }) => theme.white};
  padding: 16px 24px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  svg {
    margin-right: 10px;
  }
  ${getCssForMobileView(DownloadButtonMobile)}
`;

const DownloadContainerMobile = css`
  flex-direction: column;
  margin: 0;
  height: auto;
`;

export const DownloadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 164px;
  align-items: center;
  background-color: ${({ theme }) => theme.grey["200"]};
  margin: 98px 105px 58px;
  ${getCssForMobileView(DownloadContainerMobile)}
`;

const DownloadContainerImageMobile = css`
  height: 195px;
  width: 100%;
`;

export const DownloadContainerImage = styled.div`
  background: url("/assets/images/travel-tips-mobile.png") no-repeat center
    center;
  background-size: cover;
  width: 275px;
  height: 164px;
  ${getCssForMobileView(DownloadContainerImageMobile)}
`;

const DownloadContainerTitleMobile = css`
  margin: 36px 36px 44px;
`;

export const TextContainer = styled.div`
  max-width: 390px;
`;

export const DownloadContainerTitle = styled.p`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  margin: 34px;
  max-width: 390px;
  align-self: stretch;
  ${getCssForMobileView(DownloadContainerTitleMobile)}
`;

const DownloadPdfButtonContainerMobile = css`
  padding: 0 36px 36px;
  width: 100%;
  margin-right: 0;
`;

export const DownloadPadfButtonContainer = styled.div`
  margin-right: 49px;
  width: 184px;
  ${getCssForMobileView(DownloadPdfButtonContainerMobile)}
`;
