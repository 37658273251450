import styled from "styled-components";

export const PWAInstallationButtonContainer = styled.button`
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.white};
`;

export const PWAInstallationLogo = styled.img`
  padding: 2px;
  width: 24px;
  height: 24px;
`;

export const PWAInstallationText = styled.span`
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primaryColor["400"]};
`;

export const PWAInstallationLink = styled.button`
  background: none;
  color: inherit;
  cursor: pointer;
`;
