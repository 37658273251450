import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { THEME_400 } from "styles/default-theme";
import { getCssForMobileView } from "styles/responsive";

export const P = styled.p`
  margin: 8px 0px;
`;

export const PersonalDataTable = styled.table`
  border-collapse: collapse;
  border: 1px solid ${({ theme }) => theme.primaryColor[THEME_400]};
`;

export const TableHeader = styled.thead`
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.primaryColor[THEME_400]};
`;

const mobileTableStyles = `
  width: 100%;
`;

export const TableData = styled.td`
  padding: 32px;
  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.primaryColor[THEME_400]};
  }

  ${getCssForMobileView(mobileTableStyles)}
`;

export const TableHeadData = styled.th`
  padding: 32px;

  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.white};
  }

  ${getCssForMobileView(mobileTableStyles)}
`;

export const SectionSubtitle = styled.h3`
  font-family: ${({ theme }) => theme.secondaryFontFamily};
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin: 1.5em 0 0.5em;
  color: ${({ theme }) => theme.primaryColor[THEME_400]};
`;

export const ListItem = styled.li`
  margin-left: 16px;
`;

export const Header = styled.h2`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-size: 32px;
  line-height: 40px;
  scroll-margin-top: 70px;
  &:first-child {
    margin-bottom: 32px;
  }
`;

const makdonwComponents = {
  p: P,
  li: ListItem
};

export const MarkdownContent = ({ children }: { children: string }) => {
  return (
    <ReactMarkdown components={makdonwComponents}>{children}</ReactMarkdown>
  );
};
