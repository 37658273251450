import React, { FC } from "react";
import { withTheme } from "styled-components";
import { IconProps } from "./icon-types";

const Phone: FC<IconProps> = ({ theme }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.615 7.785C5.0642 10.6169 7.36808 12.9208 10.2 14.37L12.405 12.165C12.7022 11.8919 13.1355 11.8266 13.5 12C14.6521 12.3787 15.8573 12.5711 17.07 12.57C17.568 12.606 17.964 13.002 18 13.5V16.995C18 17.55 17.55 18 16.995 18C7.60892 18 0 10.3911 0 1.005C0 0.449954 0.449954 0 1.005 0H4.5C5.05505 0 5.505 0.449954 5.505 1.005C5.4868 2.18868 5.65382 3.36793 6 4.5C6.11381 4.86089 6.01525 5.25512 5.745 5.52L3.615 7.785Z"
        fill={theme.secondaryColor["200"]}
      />
    </svg>
  );
};

export default withTheme(Phone);
