import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

const ContainerMobile = css`
  margin-right: 0px;
  padding: 22px;
  box-shadow: inset 0px -1px 0px #f0f0f0;
`;

export const Container = styled.a`
  display: flex;
  align-items: center;
  margin-right: 34px;
  text-decoration: none;
  cursor: pointer;
  ${getCssForMobileView(ContainerMobile)}
`;

export const TextContainer = styled.span`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primaryColor["300"]};
  margin-left: 16px;
`;
