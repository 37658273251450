import React from "react";
import { withTheme } from "styled-components";
import { IconProps } from "./icon-types";

const ArrowLeftIcon = ({ theme, ...props }: IconProps) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.68191 4L3.58691 8.095L7.63691 12.144L8.34291 11.437L5.45591 8.549H13.6249V7.55H5.54591L8.38791 4.707L7.68191 4Z"
      fill={theme.primaryColor["300"]}
    />
  </svg>
);

export default withTheme(ArrowLeftIcon);
