import React from "react";
import { useTranslate } from "@customer_interactions/i18n";
import {
  CardList,
  MainContentContainer,
  Title,
  MainContainer,
  MainImageContainer
} from "./landing-styles";
import { useLanding } from "./use-landing";
import { Card } from "./cards/card";
import { useWindowSize } from "hooks/use-window-size";
import { useLiveChat } from "features/live-chat/use-live-chat";

const CARD_ICON_SIZE = 32;
const XL_CARD_ICON_SIZE = 40;

const LandingPage = () => {
  const t = useTranslate();
  useLiveChat();
  const { isExtraLargeScreen } = useWindowSize();
  const cardIconSize = isExtraLargeScreen ? XL_CARD_ICON_SIZE : CARD_ICON_SIZE;
  const { backgroundImageSet, cards, theme } = useLanding();

  return (
    <MainContainer>
      <MainImageContainer backgroundImageSet={backgroundImageSet}>
        <MainContentContainer>
          <Title>{t("[routing_page]-title")}</Title>
          <CardList>
            {cards.map(card => (
              <Card
                key={card.id}
                text={t(card.text)}
                redirection={t(card.href)}
                trackingElementName={card.text}
              >
                <card.icon
                  color={theme.viridian["400"]}
                  width={cardIconSize}
                  height={cardIconSize}
                />
              </Card>
            ))}
          </CardList>
        </MainContentContainer>
      </MainImageContainer>
    </MainContainer>
  );
};

export default LandingPage;
