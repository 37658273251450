import {
  css,
  FlattenSimpleInterpolation,
  ThemeProps,
  Interpolation
} from "styled-components";
import defaultTheme from "./default-theme";

type Theme = typeof defaultTheme;

type ThemeInterpolation =
  | FlattenSimpleInterpolation
  | Interpolation<ThemeProps<Theme>>;

export const getMobileBrakpoint = (theme: Theme) =>
  `${theme.mobileBreakPoint}${theme.breakPointUnit}`;
const getMediumBrakpoint = (theme: Theme) =>
  `${theme.mediumBreakPoint}${theme.breakPointUnit}`;
export const getExpandedBrakpoint = (theme: Theme) =>
  `${theme.expandedBreakPoint}${theme.breakPointUnit}`;
const getLargeBrakpoint = (theme: Theme) =>
  `${theme.largeBreakPoint}${theme.breakPointUnit}`;

export const getCssForMobileView = <T>(
  cssForMobileView: T & ThemeInterpolation
) => css`
  @media screen and (max-width: ${({ theme }) => getMobileBrakpoint(theme)}) {
    ${cssForMobileView}
  }
`;

export const getCssForTabletView = (
  cssForTabletView: ThemeInterpolation
) => css`
  @media (min-width: ${({ theme }) =>
      getMobileBrakpoint(theme)}) and (max-width: ${({ theme }) =>
      getMediumBrakpoint(theme)}) {
    ${cssForTabletView}
  }
`;

export const getCssForExpandedView = (
  cssForExpandedView: ThemeInterpolation
) => css`
  @media (min-width: ${({ theme }) =>
      getMediumBrakpoint(theme)}) and (max-width: ${({ theme }) =>
      getExpandedBrakpoint(theme)}) {
    ${cssForExpandedView}
  }
`;

export const getCssForLargeView = (cssForLargeView: ThemeInterpolation) => css`
  @media (min-width: ${({ theme }) =>
      getExpandedBrakpoint(theme)}) and (max-width: ${({ theme }) =>
      getLargeBrakpoint(theme)}) {
    ${cssForLargeView}
  }
`;

export const getCssForExtraLargeView = (
  cssForExtraLargeView: ThemeInterpolation
) => css`
  @media screen and (min-width: ${({ theme }) => getLargeBrakpoint(theme)}) {
    ${cssForExtraLargeView}
  }
`;
