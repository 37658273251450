import MailboxIcon, {
  MailboxIconProps
} from "app/components/icons/mailbox-icon";
import { FC } from "react";
import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

export const FlexContainerMobile = css`
  flex-direction: column;
  align-items: flex-start;
  margin-top: 65px;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  ${getCssForMobileView(FlexContainerMobile)}
`;

const TextContainerMobile = css`
  margin: 25px 25px 0;
  line-break: normal;
`;
export const TextContainer = styled.div`
  margin-left: 62px;
  margin-right: 30px;
  flex-basis: 55%;
  ${getCssForMobileView(TextContainerMobile)}
`;

export const Strong = styled.strong`
  line-break: anywhere;
`;

const ImageContainerMobile = css`
  width: 100%;
  align-items: flex-start;
  padding: 25px 40px 25px 25px;
`;
export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 90px;
  justify-content: center;
  height: 100%;
  width: 50%;
  flex-basis: 45%;
  background-color: ${({ theme }) => theme.grey["200"]};
  ${getCssForMobileView(ImageContainerMobile)}
`;

export const MailboxIconStyled = styled<FC<MailboxIconProps>>(MailboxIcon)`
  margin-bottom: 20px;
`;

const titleMobile = css`
  font-size: 36px;
  max-width: unset;
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-style: normal;
  font-weight: bold;
  font-size: 43px;
  line-height: 51px;
  color: ${({ theme }) => theme.primaryColor["300"]};
  margin-left: 10px;
  max-width: 80%;
  ${getCssForMobileView(titleMobile)}
`;

export const Text = styled.h4`
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 30px;
`;

export const ButtonContainerMobile = css`
  flex-direction: column;
  margin: auto;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  min-width: 160px;
  ${getCssForMobileView(ButtonContainerMobile)}
`;
