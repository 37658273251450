import { SagaIterator } from "redux-saga";
import { put, call, takeLatest, take } from "redux-saga/effects";
import { appLoaded } from "../features/loading/loading-actions";
import { initAnalytics } from "@customer_interactions/application-logger";
import {
  oneTrustCookiesInitialize,
  oneTrustCookiesInitializedActionType
} from "@contact_services/one-trust-cookies";
import { INIT_APP } from "./app-actions";
import { getConfigInit } from "features/configuration/actions/configuration-actions";
import { ConfigModel } from "features/configuration/configuration-types";
import { getURLKey } from "utils/route-utils";
import { getConfigFileByURL } from "features/configuration/configuration-collaborators";
import { isTestEnvironment } from "utils/variable";
import { getGlobalPartnerParametersInit } from "features/global-partner-parameters/actions/global-partner-paramenters-actions";
import { enableDebugMode } from "features/debug-mode";
import { getUrlParameters } from "features/url-parameters";

export function* initializeApp(): SagaIterator {
  const brandURL = getURLKey();
  const configuration: ConfigModel = yield call(getConfigFileByURL, brandURL);
  const partnerGATrackingId = configuration.partnerTrackingId;
  const payload = {
    googleAnalyticsPrefix: "customer_space",
    googleAnalyticsTrackingIds: [
      partnerGATrackingId,
      process.env.REACT_APP_GLOBAL_GA_TRACKING_ID
    ],
    appInsightsId: process.env.REACT_APP_CONNECTION_STRING,
    gtmId: process.env.REACT_APP_GTM_ID,
    enabledConsoleLog: true
  };

  if (configuration.isDebuggable) {
    yield put(enableDebugMode());
  }

  if (configuration.persistQueryParameters) {
    yield put(getUrlParameters());
  }

  yield put(initAnalytics(payload));
  yield put(getConfigInit());
  yield put(getGlobalPartnerParametersInit());
  yield put(
    oneTrustCookiesInitialize(
      configuration.one_trust_data_domain_id,
      {
        hideCloseButton: true
      },
      isTestEnvironment()
    )
  );
  yield take(oneTrustCookiesInitializedActionType);
  yield put(appLoaded());
}

export default function* main() {
  yield takeLatest(INIT_APP, initializeApp);
}
