import { SagaIterator } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";
import { getQueryParamValue } from "utils/query-params-utils";
import {
  GLOBAL_PARTNER_PARAMETERS_FEATURE,
  getGlobalPartnerParametersSuccess
} from "../actions/global-partner-paramenters-actions";
import { sanitizeObject } from "utils/sanitize-object";

const PARTNER_ID_QUERY_PARAM = "utm_campaign";
const PARTNER_NAME_QUERY_PARAM = "utm_medium";
const COUNTRY_QUERY_PARAM = "utm_content";

export function* getGlobalPartnerParametersSaga(): SagaIterator {
  try {
    const newQueryParams = sanitizeObject({
      partnerId: getQueryParamValue(PARTNER_ID_QUERY_PARAM),
      partnerName: getQueryParamValue(PARTNER_NAME_QUERY_PARAM),
      country: getQueryParamValue(COUNTRY_QUERY_PARAM)
    });

    if (Object.keys(newQueryParams).length === 0) {
      return;
    }

    yield put(getGlobalPartnerParametersSuccess(newQueryParams));
  } catch (error) {
    console.log(error);
  }
}

export default function* main() {
  yield takeLatest(
    GLOBAL_PARTNER_PARAMETERS_FEATURE.INIT,
    getGlobalPartnerParametersSaga
  );
}
