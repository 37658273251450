import { LinkResolver } from "app/components/link-resolver/link-resolver";
import { imagesBaseURL } from "constant";
import styled, { css } from "styled-components";
import { SectionColumnContainer, SectionRowContainer } from "styles";
import { getCssForMobileView } from "styles/responsive";

const CardContainerMobile = css`
  flex-basis: 100%;
  margin: 1rem 0;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  gap: 0.5rem;
  margin: 1rem;
  flex: 1;
  ${getCssForMobileView(CardContainerMobile)};
`;

const CardTextContainerMobile = css`
  margin: 0 1rem;
  width: calc(100% - 2rem);
`;

export const TripCardTitle = styled.h3`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-weight: bold;
  font-size: 28px;
  line-height: 35px;
  ${getCssForMobileView(CardTextContainerMobile)}
`;

const TripCardTextMobile = css`
  margin: 1rem;
`;
export const TripCardText = styled.div`
  font-family: ${({ theme }) => theme.secondaryFontFamily};
  font-size: 18px;
  line-height: 24px;
  flex: 1;
  margin: 1rem 0;
  ${getCssForMobileView(TripCardTextMobile)}
`;
interface ImageContainerProps {
  imageName: string;
}
export const ImageContainer = styled.div<ImageContainerProps>`
  width: 100%;
  height: 220px;
  background-size: cover;
  background-image: url(${({ imageName }) =>
    `${imagesBaseURL}${imageName}.png`});
  background-position: center;
`;

const TripSectionContainerStyles = css`
  margin: 1em 0;
`;

export const TripSectionContainer = styled.section`
  background-color: ${({ theme }) => theme.grey["100"]};
`;

export const TripSectionFlexContainer = styled(SectionColumnContainer)`
  align-items: center;
  ${getCssForMobileView(TripSectionContainerStyles)};
`;

export const TripSectionRowContainer = styled(SectionRowContainer)`
  ${getCssForMobileView(TripSectionContainerStyles)};
`;

export const GetSupportContainer = styled.div`
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  ${getCssForMobileView(CardTextContainerMobile)}
`;

const DropdownContentMobile = css`
  white-space: pre-wrap;
  width: 100%;
`;
export const DropdownContent = styled.div`
  position: absolute;
  top: 100%;
  white-space: nowrap;
  left: 0;
  text-transform: uppercase;
  padding: 8px 0;
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.primaryColor["300"]};
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3));
  ${getCssForMobileView(DropdownContentMobile)}
`;

interface DropdownItemVariants {
  [key: string]: any;
}

const dropdownItemVariantStyles = (theme: any, variant = "primary") => {
  const variants: DropdownItemVariants = {
    primary: css`
      color: ${theme.grey["800"]};
      &:hover {
        color: ${theme.secondaryColor["200"]};
      }
    `,
    secondary: css`
      color: ${theme.primaryColor["300"]};
    `
  };

  return variants[variant];
};

export const DropdownItem = styled(LinkResolver)`
  text-decoration: none;
  padding: 8px 24px;
  gap: 8px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${({ theme }) => theme.grey["200"]};
  }
  ${({ theme, variant }) => dropdownItemVariantStyles(theme, variant)}
`;

export const OpenDropdownButton = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 16px 0 16px 24px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.primaryColor["300"]};
  color: ${({ theme }) => theme.white};
`;

export const PlusSpanButton = styled.span`
  margin: 0 16px;
`;

export const LabelSpanButton = styled.span`
  flex: 1;
`;
