import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

const CookiesInfoContainerMobile = css`
  margin: 85px 10% 50px;
`;

export const CookiesInfoContainer = styled.div`
  margin: 60px 10%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: justify;
  ${getCssForMobileView(CookiesInfoContainerMobile)}
`;

export const CookiesIntroText = styled.p`
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  padding-bottom: 10px;
`;

export const CookiesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CookieSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CookiesSectionTitle = styled.h3`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.primaryColor["400"]};
`;

export const CookiesSectionText = styled.p`
  font-size: 16px;
  line-height: 24px;
`;

export const StyledUl = styled.ul`
  margin-left: 2rem;
  padding-bottom: 1em;
`;
