import { debounce } from "lodash";
import { trackSearchEvent } from "features/analytics-events/track-search-event";
import { trackNavigationEvent } from "features/analytics-events/track-navigation-event";
import { CULTURA_GUIDES_ZONES } from "./cultura-guides-collaborators";
import { mapElementClickedName } from "utils/analytics-mapper-utils";

const EVENT_DEBOUNCE_TIME = 300;

export const handleSelectGuide = () =>
  trackNavigationEvent(
    CULTURA_GUIDES_ZONES.HOME_PAGE,
    mapElementClickedName["guide"]
  );

export const handleDropdownEvent = () =>
  trackNavigationEvent(
    CULTURA_GUIDES_ZONES.BOTTOM,
    mapElementClickedName["chapter"]
  );

export const handleSearch = debounce(
  () =>
    trackSearchEvent(
      mapElementClickedName["search"],
      CULTURA_GUIDES_ZONES.HOME_PAGE
    ),
  EVENT_DEBOUNCE_TIME
);

export const handleTableOfContent = () =>
  trackNavigationEvent(
    CULTURA_GUIDES_ZONES.TABLE_OF_CONTENT,
    mapElementClickedName["chapter"]
  );

export const handleBackEvent = () =>
  trackNavigationEvent(
    CULTURA_GUIDES_ZONES.TABLE_OF_CONTENT,
    mapElementClickedName["back"]
  );

export const handlePrevious = () =>
  trackNavigationEvent(
    CULTURA_GUIDES_ZONES.BOTTOM,
    mapElementClickedName["previous"]
  );

export const handleNext = () =>
  trackNavigationEvent(
    CULTURA_GUIDES_ZONES.BOTTOM,
    mapElementClickedName["next"]
  );
