import React from "react";
import { Route, Switch } from "react-router-dom";
import { routes } from "constant";
import HomePage from "pages/home/home";
import TravelTips from "pages/travel-tips/travel-tips";
import { CookiesPage } from "pages/cookies/cookies";
import { PrivacyPolicyPage } from "pages/privacy-policy/privacy-policy";
import { FaqsPage } from "pages/faqs/faqs";
import { MedicalProviderSearchPage } from "pages/medical-providers-search";
import { PublicRoute } from "app/components/shared/route-types/public-route";
import CountryInformation from "pages/country-information/country-information";
import NotFound from "pages/not-found/not-found";
import UnderMaintenance from "pages/under-maintenance/under-maintenance";
import LayoutPage from "pages/layouts/main-layout/layout-page";
import EmailNotVerified from "pages/email-not-verified/email-not-verified";
import { TravelTipsPDF } from "pages/travel-tips/travel-tips-pdf";
import Login from "pages/login/login";
import CultureGuides from "pages/cultura-guides/cultura-guides";
import LandingPage from "pages/landing/landing";
import AlternativeLayoutPage from "pages/layouts/alternative-layout/alternative-layout-page";
import HelpCenter from "pages/help-center/help-center";
import { LoadingScreenWrapper } from "./loading-screen-wrapper";
import { usePageView } from "hooks/use-pageview";

export const UnderMaintenanceRoutes = () => (
  <LoadingScreenWrapper>
    <Switch>
      <Route
        path={routes.FAQS}
        component={() => (
          <LayoutPage>
            <FaqsPage />
          </LayoutPage>
        )}
      />
      <Route path="*" component={UnderMaintenance} />
    </Switch>
  </LoadingScreenWrapper>
);

export const RoutingPageRoutes = () => {
  usePageView();
  return (
    <LoadingScreenWrapper>
      <AlternativeLayoutPage>
        <Switch>
          <Route
            path={[routes.ROOT, routes.HOME]}
            component={LandingPage}
            exact
          />
          <Route path={routes.HELP_CENTER} component={HelpCenter} exact />
          <Route
            path={routes.PRIVACY_POLICY}
            component={PrivacyPolicyPage}
            exact
          />
          <Route path={routes.COOKIES} component={CookiesPage} exact />
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </AlternativeLayoutPage>
    </LoadingScreenWrapper>
  );
};

export const AppRoutes = ({
  isEmailVerified,
  isAuthenticated,
  isAuth0Loading
}: {
  isEmailVerified: boolean;
  isAuthenticated: boolean;
  isAuth0Loading: boolean;
}) => (
  <LoadingScreenWrapper externalLoading={isAuth0Loading}>
    <LayoutPage>
      <Switch>
        <Route path={routes.LOGIN} exact>
          <Login />
        </Route>
        <PublicRoute
          isAuthenticated={isAuthenticated}
          isEmailVerified={isEmailVerified}
          path={[
            routes.EMAIL_ACQUISITION,
            routes.SMS_ACQUISITION,
            routes.TRAVEL_CARD_ACQUISITION,
            routes.POLICY_CERTIFICATE_ACQUISITION
          ]}
          component={HomePage}
          exact
        />
        <PublicRoute
          isEmailVerified={isEmailVerified}
          isAuthenticated={isAuthenticated}
          path={[routes.ROOT, routes.HOME]}
          component={HomePage}
          exact
        />
        <PublicRoute
          isEmailVerified={isEmailVerified}
          isAuthenticated={isAuthenticated}
          path={routes.TRAVEL_TIPS}
          component={TravelTips}
          exact
        />
        <PublicRoute
          isAuthenticated={isAuthenticated}
          path={routes.PRIVACY_POLICY}
          component={PrivacyPolicyPage}
          exact
        />
        <PublicRoute
          isAuthenticated={isAuthenticated}
          path={routes.COOKIES}
          component={CookiesPage}
          exact
        />
        <PublicRoute
          isEmailVerified={isEmailVerified}
          isAuthenticated={isAuthenticated}
          path={routes.FAQS}
          component={FaqsPage}
          exact
        />
        <PublicRoute
          isEmailVerified={isEmailVerified}
          isAuthenticated={isAuthenticated}
          path={routes.MEDICAL_SEARCH}
          component={MedicalProviderSearchPage}
          exact
        />
        <PublicRoute
          isEmailVerified={isEmailVerified}
          isAuthenticated={isAuthenticated}
          path={routes.COUNTRY_INFORMATION}
          component={CountryInformation}
          exact
        />
        <PublicRoute
          isEmailVerified={isEmailVerified}
          isAuthenticated={isAuthenticated}
          path={routes.COUNTRY_GUIDES}
          component={CultureGuides}
          exact
        />
        <PublicRoute
          isEmailVerified={isEmailVerified}
          isAuthenticated={isAuthenticated}
          path={routes.TRAVEL_TIPS_PDF}
          component={TravelTipsPDF}
        />
        <Route path={routes.EMAIL_NOT_VERIFIED} exact>
          <EmailNotVerified />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </LayoutPage>
  </LoadingScreenWrapper>
);
