import { State } from "app/store/types";

import { ConfigModel } from "../configuration-types";

export const selectConfiguration = (state: State): ConfigModel =>
  state.configuration;

export const selectPartnerId = (state: State) =>
  selectConfiguration(state).partnerId;

export const selectAnalyticsPartnerName = (state: State) =>
  selectConfiguration(state).analyticsConfig?.partnerName;

export const selectAnalyticsPartnerId = (state: State) =>
  selectConfiguration(state).analyticsConfig?.partnerId;

export const selectAssistanceServices = (state: State) =>
  selectConfiguration(state).assistanceServices;

export const selectFavicon = (state: State) =>
  selectConfiguration(state).favicon;

export const selectLanguages = (state: State) =>
  selectConfiguration(state).languages_iso_codes || [];

export const selectDigitalCardConfig = (state: State) =>
  selectConfiguration(state).digitalCard;

export const selectGoBackButton = (state: State) =>
  selectConfiguration(state).navBar.hasGoBackButton;

export const selectRedirectUrls = (state: State) =>
  selectConfiguration(state).navBar.redirectUrls || {};

export const selectLogo = (state: State) => selectConfiguration(state).logo;

export const selectManifestId = (state: State) =>
  selectConfiguration(state).manifestId;

export const selectUseCustomLanguageInFAQ = (state: State) =>
  selectConfiguration(state).useCustomLanguageInFAQ;

export const selectPrivacyPolicySections = (state: State) =>
  selectConfiguration(state).privacyPolicySections;

export const selectGlobalDenomination = (state: State) =>
  selectConfiguration(state).globalDenomination;

export const selectPartnerCountry = (state: State) =>
  selectConfiguration(state).partnerCountry;

export const selectVideoLocales = (state: State) =>
  selectConfiguration(state).videoLocales;

export const selectUseAlternativeHomepage = (state: State) =>
  selectConfiguration(state).useAlternativeHomepage;

const selectLiveChatConfig = (state: State) =>
  selectConfiguration(state).live_chat || {};

export const selectLiveChatEnabled = (state: State) =>
  Boolean(selectLiveChatConfig(state).enabled);

export const selectLiveChatWidgetId = (state: State) =>
  selectLiveChatConfig(state).widget_id || "";

export const selectFloatingWhatsapp = (state: State) =>
  selectConfiguration(state).floatingWhatsapp;
