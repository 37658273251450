import { useEffect } from "react";
import { getPageViewEventData } from "features/analytics-events/get-page-view-event-data";
import { Analytics } from "@customer_interactions/application-logger";
import { useAuth0 } from "@auth0/auth0-react";
import { selectUseAlternativeHomepage } from "features/configuration/selectors/configuration-selectors";
import { useSelector, useStore } from "react-redux";
import { useLocation } from "react-router-dom";

export const usePageView = () => {
  const store = useStore();
  const useAlternativeHomepage = useSelector(selectUseAlternativeHomepage);
  const { isAuthenticated } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    const pageViewEventData = getPageViewEventData({
      isLoggedIn: isAuthenticated,
      state: store.getState(),
      isRoutingPage: useAlternativeHomepage,
      route: location.pathname
    });
    if (pageViewEventData) {
      Analytics.sendEventData(pageViewEventData);
    }
  }, [location.pathname]);
};
