import { State } from "app/store/types";
import { selectCurrentLanguageIsoCode } from "features/language/selectors/language-selector";
import {
  EVENT_NAMES,
  mapUrlsToEventNames,
  mapUrlsToServiceCategory,
  getPartnerId,
  getCountry,
  getPartnerName,
  SUBSIDIARY_NAME,
  BUSINESS_UNIT,
  BUSINESS_LINE,
  DIGITAL_BUSINESS_PROCESS,
  GLOBAL_DIGITAL_ASSET,
  getEnvironmentName,
  ROUTING_PAGE_DIGITAL_BUSINESS_PROCESS,
  ROUTING_PAGE_GLOBAL_DIGITAL_ASSET
} from "./common";

export const commonPayload = {
  subsidiary_name: SUBSIDIARY_NAME,
  business_unit: BUSINESS_UNIT,
  business_line: BUSINESS_LINE,
  digital_business_process: DIGITAL_BUSINESS_PROCESS,
  global_digital_asset: GLOBAL_DIGITAL_ASSET
};

export const routingPageCommonPayload = {
  ...commonPayload,
  digital_business_process: ROUTING_PAGE_DIGITAL_BUSINESS_PROCESS,
  global_digital_asset: ROUTING_PAGE_GLOBAL_DIGITAL_ASSET
};

interface PageEventProps {
  route?: string;
  isLoggedIn: boolean;
  state: State;
  pageName?: string;
  serviceCategory?: string;
  isRoutingPage?: boolean;
}

export const getPageViewEventData = ({
  route,
  isLoggedIn,
  state,
  pageName,
  serviceCategory,
  isRoutingPage = false
}: PageEventProps) => {
  const page_name = route ? mapUrlsToEventNames[route] : pageName;

  if (!page_name) {
    return null;
  }

  let serviceCategoryForPage = serviceCategory;
  if (!serviceCategory && route) {
    serviceCategoryForPage = mapUrlsToServiceCategory[route];
  }

  const partnerId = getPartnerId(state);
  const partnerName = getPartnerName(state);
  const country = getCountry(state);
  const language = selectCurrentLanguageIsoCode(state);

  return {
    event: EVENT_NAMES.PAGEVIEW,
    page_name: page_name,
    partner_id: partnerId,
    partner_name: partnerName,
    country,
    language,
    user_logged: isLoggedIn,
    instance_environment: getEnvironmentName(),
    service_category: serviceCategoryForPage,
    ...(isRoutingPage ? routingPageCommonPayload : commonPayload)
  };
};
