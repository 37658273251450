import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";
import ScrollUpIcon from "app/components/icons/scroll-up-icon";

const MobileButton = css`
  top: 80%;
  right: 3%;
`;

export const Button = styled.button`
  position: fixed;
  z-index: 9999;
  top: 70%;
  right: 2%;
  cursor: pointer;
  background: transparent;
  height: 66px;
  width: 63px;
  &:focus {
    box-sizing: border-box;
    outline: ${({ theme }) => theme.secondaryColor["100"]}4D solid 2px;
    border-radius: 2px;
  }
  ${getCssForMobileView(MobileButton)}
`;

export const IconButton = styled(ScrollUpIcon)`
  height: 70px;
  & circle {
    fill: ${({ theme }) => theme.secondaryColor["200"]};
    &:hover {
      fill: ${({ theme }) => theme.secondaryColor["300"]};
    }
    &:focus {
      fill: ${({ theme }) => theme.secondaryColor["200"]};
    }
  }
`;
