export enum STAGE {
  BEFORE = "before",
  DURING = "during",
  AFTER = "after"
}

export const imagesBaseURL = "/assets/images/";

export const routes = {
  LOGIN: "/login",
  HOME: "/home",
  ROOT: "/",
  COOKIES: "/cookies",
  EMAIL_NOT_VERIFIED: "/not_verified",
  VERIFY_EMAIL_LINK: "/refresh_session",
  TRAVEL_TIPS: "/travel_tips",
  PRIVACY_POLICY: "/privacy_policy",
  FAQS: "/faqs",
  MEDICAL_SEARCH: "/medical_providers_search",
  HELP_CENTER: "/help_center",
  COUNTRY_INFORMATION: "/country",
  COUNTRY_GUIDES: "/guide",
  EMAIL_ACQUISITION: "/email",
  SMS_ACQUISITION: "/sms",
  TRAVEL_CARD_ACQUISITION: "/travel-card",
  POLICY_CERTIFICATE_ACQUISITION: "/policy-certificate",
  REFRESH_SESSION: "/refresh_session",
  TRAVEL_TIPS_PDF: "/travel_tips_pdf"
};

export type RouteId = keyof typeof routes;

export const routesWithScrollUpButton = [
  routes.ROOT,
  routes.HOME,
  routes.TRAVEL_TIPS,
  routes.PRIVACY_POLICY,
  routes.COOKIES
];

export const LandingPrefix = "Landing";
export const HomePrefix = "Home";

export const serviceToTripStage: { [key: string]: string } = {
  travel: STAGE.BEFORE,
  medical: STAGE.DURING,
  claim: STAGE.AFTER
};

export const subsectionIdToTagName: { [key: string]: string } = {
  // TRAVEL SUBSECTIONS
  country: "Country_Information",
  country_guides: "Country_Guides",
  tips: "Travel_tip",
  // MEDICAL SUBSECTIONS
  nurse: "Nurse_Contact",
  chat: "Medical_Chat",
  speak: "Teleconsulting_Speak",
  locate: "Locate_Specialist",
  emergency: "Emergency_numbers",
  // CLAIM
  notify: "Notify_Claim",
  follow: "Follow_Claim"
};

export const TipsByStages = [
  {
    stage: STAGE.BEFORE,
    tabPoTag: "[travel_tips][main]before",
    prefix: "[travel_tips][before]",
    items: 20
  },
  {
    stage: STAGE.DURING,
    tabPoTag: "[travel_tips][main]during",
    prefix: "[travel_tips][during]",
    items: 10
  },
  {
    stage: STAGE.AFTER,
    tabPoTag: "[travel_tips][main]after",
    prefix: "[travel_tips][after]",
    items: 10
  }
];

export enum SherpaAllowedLanguages {
  "en",
  "es",
  "fr",
  "de",
  "nl"
}

export const LanguageParamName = "lng_code";

export const NAV_DESKTOP_CONTAINER_ID = "mtc-nav-desktop-container";

export const NAV_MOBILE_CONTAINER_ID = "mtc-nav-mobile-container";

export const LANGUAGE_SELECTOR_MOBILE_CONTAINER_ID =
  "mtc-language-selector-container";

export const LANGUAGE_SELECTOR_DESKTOP_CONTAINER_ID =
  "mtc-language-selector-container";
