import React, { FC } from "react";
import { withTheme } from "styled-components";
import { IconProps } from "./icon-types";

const ScrollUpIcon: FC<IconProps> = ({ theme, ...props }: IconProps) => {
  return (
    <svg
      width="59"
      height="59"
      viewBox="0 0 59 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_3448_27937)">
        <circle
          cx="29.0515"
          cy="25"
          r="25"
          fill={theme.secondaryColor["200"]}
        />
      </g>
      <path
        d="M28.5546 30.5L28.5546 20"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M32.0546 22L28.5546 19.2023L25.0546 22"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <defs>
        <filter
          id="filter0_d_3448_27937"
          x="0.0515137"
          y="0"
          width="58"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3448_27937"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3448_27937"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default withTheme(ScrollUpIcon);
