import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

const ContainerMobile = css`
  padding: 25px;
`;
export const Container = styled.div`
  padding: 25px 125px;
  display: flex;
  flex-direction: column;
  ${getCssForMobileView(ContainerMobile)}
`;
const NavMobile = css`
  top: 40px;
  margin-top: 70px;
  margin-bottom: 0px;
`;

export const Nav = styled.nav`
  position: sticky;
  top: 0px;
  z-index: 2000;
  background: ${({ theme }) => theme.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  overflow-x: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  ${getCssForMobileView(NavMobile)}
`;

const TabMobile = css`
  padding: 15px 16px;
  flex: 1 0 150px;
`;

export const TabOption = styled.a`
  flex-grow: 1;
  height: 65px;
  padding: 24px 16px;
  text-align: center;
  text-transform: uppercase;
  word-break: break-word;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryColor["300"]};
  &.active {
    border-bottom: ${({ theme }) => `3px solid ${theme.tertiaryColor}`};
  }
  ${getCssForMobileView(TabMobile)}
`;
