import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";
import ClaimIcon from "app/components/icons/claim-icon";
import BagaggeIcon from "app/components/icons/bagagge-icon";
import MedicalIcon from "app/components/icons/medical-icon";

const AssistanceItemContainerMobile = css`
  order: unset;
  flex-direction: column;
  width: 90%;
  margin: 8px auto;
`;

export const AssistanceItemContainer = styled.section<{ order: number }>`
  display: flex;
  flex: 1;
  align-self: stretch;
  order: ${({ order }) => order};
  ${getCssForMobileView(AssistanceItemContainerMobile)}
`;

const AssistanceItemCardMobileStyles = css`
  flex-basis: 100%;
  height: auto;
  gap: 10px;
  border-radius: 4px;
  background: ${({ theme }) => theme.white};
  box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 25%);
  justify-content: flex-end;
  align-items: flex-start;
`;

export const AssistanceItemCard = styled.div<{ isItemSelected: boolean }>`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  flex: 1;
  height: 100%;
  background: ${({ isItemSelected, theme }) =>
    isItemSelected ? theme.grey["200"] : "inherit"};
  ${getCssForMobileView(AssistanceItemCardMobileStyles)}
`;

const ItemTitleMobileStyles = css`
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  padding: 0px;
`;

export const ItemTitle = styled.h2`
  font-family: ${({ theme }) => theme.secondaryFontFamily};
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  padding: 0.5rem 0px;
  color: ${({ theme }) => theme.secondaryColor["200"]};
  ${getCssForMobileView(ItemTitleMobileStyles)}
`;

export const ItemText = styled.p`
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;
  font-weight: 600;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
`;

const mobileIconStyles = css`
  height: 32px;
  width: 32px;
`;

export const TravelItemIcon = styled(BagaggeIcon)`
  ${getCssForMobileView(mobileIconStyles)}
`;

export const MedicalItemIcon = styled(MedicalIcon)`
  ${getCssForMobileView(mobileIconStyles)}
`;

export const ClaimItemIcon = styled(ClaimIcon)`
  ${getCssForMobileView(mobileIconStyles)}
`;
