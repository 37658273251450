import React from "react";
import { withTheme } from "styled-components";
import { IconProps } from "./icon-types";

const MedicalIcon = ({ theme, ...props }: IconProps) => (
  <svg
    width="52"
    height="50"
    viewBox="0 0 52 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.8644 27.0827H17.5793V20.8327H22.8644V15.6243H29.2066V20.8327H34.4918V27.0827H29.2066V32.291H22.8644V27.0827ZM26.0355 4.16602L9.12305 10.416V23.1035C9.12305 33.6244 16.332 43.4369 26.0355 45.8327C35.7391 43.4369 42.948 33.6244 42.948 23.1035V10.416L26.0355 4.16602ZM38.7199 23.1035C38.7199 31.4369 33.329 39.1452 26.0355 41.4994C18.742 39.1452 13.3512 31.4577 13.3512 23.1035V13.3118L26.0355 8.62435L38.7199 13.3118V23.1035Z"
      fill={theme.secondaryColor["200"]}
    />
  </svg>
);

export default withTheme(MedicalIcon);
