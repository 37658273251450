import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";
import { LoginButtonStyled } from "../shared/login-button/login-button-styles";

const SubsectionItemCardStyles = css`
  flex-basis: 100%;
  flex-shrink: 0;
  padding: 10px 0px;
  margin: 0;
`;

export const SubsectionItemCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px 16px;
  flex-basis: 33%;
  margin: 1rem 0;
  ${getCssForMobileView(SubsectionItemCardStyles)}
`;

export const SubsectionItemTitle = styled.h4`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-weight: bold;
  font-size: 21px;
  line-height: 150%;
  color: ${({ theme }) => theme.grey["800"]};
  padding: 15px 0px;
`;

export const SubsectionItemText = styled.p`
  font-family: ${({ theme }) => theme.secondaryFontFamily};
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.grey["800"]};
  flex: 1;
`;

export const AssistanceServiceLoginButton = styled(LoginButtonStyled)`
  margin: 1rem 0;
  padding: 16px 24px;
`;
