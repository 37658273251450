import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";

export const HeadingSection = styled.div`
  display: flex;
  padding: 74px;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: ${({ theme }) => theme.secondaryColor["200"]};

  ${getCssForMobileView(css`
    margin-top: 115px;
    padding: 67px 24px 54px;
  `)}
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-weight: 700;
  font-size: 3rem;
  line-height: 56px;
  color: ${({ theme }) => theme.white};
  text-align: center;

  ${getCssForMobileView(css`
    font-size: 1.5rem;
    line-height: 32px;
    text-align: start;
  `)}
`;

export const DescriptionSection = styled.div`
  display: flex;
  gap: 32px;
  justify-content: center;
  width: 100%;
  padding: 80px 74px;

  ${getCssForMobileView(css`
    padding: 40px 24px;
    flex-direction: column;
  `)}
`;

export const DescriptionCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 500px;
`;

export const DescriptionCardTitle = styled.h2`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-weight: 700;
  font-size: 2rem;
  line-height: 40px;

  ${getCssForMobileView(css`
    font-size: 1.25rem;
    line-height: 32px;
  `)}
`;

export const DescriptionCardText = styled.p`
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 28px;

  ${getCssForMobileView(css`
    font-size: 1rem;
    line-height: 24px;
  `)}
`;
