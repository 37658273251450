import styled, { css } from "styled-components";
import { getCssForMobileView, getCssForTabletView } from "styles/responsive";

export const GradientContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

const BaseSectionMobile = css`
  flex-direction: column;
`;

export const BaseSection = styled.section`
  display: flex;
  ${getCssForMobileView(BaseSectionMobile)}
`;

const BaseSectionLeftMobile = css`
  width: 100%;
  padding: 36px 18px;
  text-align: left;
`;

const BaseSectionLeftTablet = css`
  padding: 0 8px;
`;

export const BaseSectionLeft = styled.div<{ color?: string }>`
  flex: 0 1 50%;
  display: flex;
  padding: 156px 82px;
  justify-content: center;
  flex-direction: column;
  background: ${({ theme, color }) => color ?? theme.grey["200"]};
  ${getCssForMobileView(BaseSectionLeftMobile)}
  ${getCssForTabletView(BaseSectionLeftTablet)}
`;

const BaseSectionTitleMobile = css`
  margin-bottom: 24px;
  font-size: 28px;
`;

export const BaseSectionTitle = styled.h2<{ color?: string }>`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-size: 36px;
  font-weight: bold;
  line-height: 42px;
  margin-bottom: 20px;
  color: ${({ theme, color }) => color ?? theme.dark};
  ${getCssForMobileView(BaseSectionTitleMobile)}
  ${getCssForTabletView(BaseSectionTitleMobile)}
`;

export const BaseSectionSubTitle = styled.p<{ color?: string }>`
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
  color: ${({ theme, color }) => color ?? theme.dark};
`;

export const TextCardContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.dark};
`;
