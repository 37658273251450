import React from "react";
import { Analytics } from "@customer_interactions/application-logger";
import { useTranslate } from "@customer_interactions/i18n";
import {
  FooterContainer,
  FlexColumn,
  RightsReserved,
  Link,
  RightsReservedText,
  FooterListItem
} from "./footer-styles";
import { CookiesSettings } from "../cookies/cookies";
import { PWAInstallationButton } from "../pwa-installation-button";

const handleClickOf = (linkName: string) => () => {
  Analytics.sendEvent(
    `[customer_space_landing] ${linkName}-click`,
    "Customer_Space"
  );
};

const handleCookiesSettingsClick = () =>
  Analytics.sendEvent(`[Footer]Preference_center-click`, "Customer_Space");

export const Footer = () => {
  const t = useTranslate();
  const appVersion = process.env.REACT_APP_VERSION;
  return (
    <FooterContainer>
      <FlexColumn>
        <Link
          data-testid="terms-and-conditions"
          to={t("[Landing_footer]Terms_conditions_url")}
          onClick={handleClickOf("terms_conditions")}
        >
          {t("[Landing_footer]Terms_conditions")}
        </Link>
        <Link
          data-testid="cookies"
          to={t("[Landing_footer]Cookies_url")}
          onClick={handleClickOf("cookies")}
        >
          {t("[Landing_footer]Cookies")}
        </Link>
        <FooterListItem>
          <CookiesSettings onClick={handleCookiesSettingsClick} />
        </FooterListItem>
        <FooterListItem>
          <PWAInstallationButton isTextButton />
        </FooterListItem>
      </FlexColumn>
      <RightsReserved>
        <RightsReservedText data-testid="rights-reserved-text">
          {t("[footer]rights_text", false, {
            year: new Date().getFullYear(),
            appVersion
          })}
        </RightsReservedText>
      </RightsReserved>
    </FooterContainer>
  );
};
