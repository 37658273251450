import styled from "styled-components";
import { LinkResolver } from "../link-resolver/link-resolver";

export const Button = styled(LinkResolver)`
  color: ${({ theme }) => theme.primaryColor["300"]};
  margin-inline-start: 0.5rem;
  margin-inline-end: auto;
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  svg {
    margin-inline-end: 0.5rem;
  }
`;
