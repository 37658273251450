import { useTheme } from "styled-components";
import { Theme } from "styles";

export const useWCTheme = () => {
  const theme = useTheme() as Theme;

  return {
    ...theme,
    primaryColor: theme.primaryColor["400"],
    secondaryColor: theme.secondaryColor["200"]
  };
};
