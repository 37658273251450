export const mapElementClickedName: { [term: string]: string } = {
  ["[routing_page]-button_1"]: "I have a coverage query question",
  ["[routing_page]-button_2"]: "I want to make a claim",
  ["[routing_page]-button_3"]: "I need a medical assistance",
  ["[routing_page]-button_4"]: "I want to track a claim",
  search: "country_destination_search",
  guide: "guide",
  chapter: "chapter",
  back: "back",
  previous: "previous",
  next: "next"
};
