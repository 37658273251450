import React from "react";
import { withTheme } from "styled-components";
import { IconProps } from "./icon-types";

const GenericContactUsIcon = ({ theme, ...props }: IconProps) => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.392 13.0396C18.777 12.1035 18.986 11.1013 18.986 10C18.986 9.20705 18.865 8.44714 18.656 7.74229C17.941 7.90749 17.193 7.99559 16.412 7.99559C13.046 7.99559 10.065 6.3326 8.25 3.76652C7.271 6.14537 5.401 8.0837 3.047 9.14097C3.003 9.4163 3.003 9.71366 3.003 10C3.003 12.1235 3.84554 14.16 5.34527 15.6615C6.845 17.1631 8.87906 18.0066 11 18.0066C12.155 18.0066 13.266 17.7533 14.267 17.3018C14.894 18.5022 15.18 19.0969 15.158 19.0969C13.354 19.7026 11.957 20 11 20C8.338 20 5.797 18.9537 3.927 17.0705C2.783 15.9251 1.936 14.5264 1.463 13.0066H0V7.99559H1.199C2.123 3.43612 6.16 0 11 0C13.64 0 16.181 1.04626 18.062 2.92952C19.459 4.31718 20.394 6.09031 20.779 7.99559H22V12.9405V12.9626V13.0066H21.934L18.018 16.6079L12.188 15.9471V14.1079H17.501L18.392 13.0396ZM7.997 9.7467C8.327 9.7467 8.646 9.87885 8.877 10.1211C9.108 10.3524 9.24 10.6718 9.24 11.0022C9.24 11.3326 9.108 11.652 8.877 11.8833C8.646 12.1145 8.327 12.2467 7.997 12.2467C7.304 12.2467 6.743 11.696 6.743 11.0022C6.743 10.3084 7.304 9.7467 7.997 9.7467ZM13.992 9.7467C14.685 9.7467 15.235 10.3084 15.235 11.0022C15.235 11.696 14.685 12.2467 13.992 12.2467C13.299 12.2467 12.738 11.696 12.738 11.0022C12.738 10.6692 12.8701 10.3499 13.1053 10.1144C13.3405 9.87897 13.6594 9.7467 13.992 9.7467Z"
      fill={theme.secondaryColor["200"]}
    />
  </svg>
);

export default withTheme(GenericContactUsIcon);
