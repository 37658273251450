import React from "react";
import { FaqsPage } from "pages/faqs/faqs";
import { MainContainer } from "pages/home/home-styles";
import {
  DescriptionSection,
  HeadingSection,
  Title
} from "./help-center-styles";
import { useTranslate } from "@customer_interactions/i18n";
import { HelpCenterCard } from "./help-center-card";
import { useLiveChat } from "features/live-chat/use-live-chat";
import { useSelector } from "react-redux";
import { selectFloatingWhatsapp } from "features/configuration/selectors/configuration-selectors";
import { FloatingWhatsapp } from "features/floating-whatsapp/floating-whatsapp";

export const HELP_CENTER_TAGS = {
  title: "[routing_page]-FAQ-main-title",
  card1Title: "[routing_page]-FAQ-static-title-1",
  card1Text: "[routing_page]-FAQ-static-subtitle-1",
  card2Title: "[routing_page]-FAQ-static-title-2",
  card2Text: "[routing_page]-FAQ-static-subtitle-2"
};

const HelpCenter = () => {
  const t = useTranslate();
  const { isEnabled: isLiveChatEnabled } = useLiveChat();
  const floatingWhatsapp = useSelector(selectFloatingWhatsapp);

  return (
    <>
      <MainContainer>
        <HeadingSection>
          <Title>{t(HELP_CENTER_TAGS.title)}</Title>
        </HeadingSection>
        <DescriptionSection>
          <HelpCenterCard
            title={t(HELP_CENTER_TAGS.card1Title)}
            description={t(HELP_CENTER_TAGS.card1Text)}
          />
          {isLiveChatEnabled && (
            <HelpCenterCard
              title={t(HELP_CENTER_TAGS.card2Title)}
              description={t(HELP_CENTER_TAGS.card2Text)}
            />
          )}
          {floatingWhatsapp?.link && (
            <FloatingWhatsapp link={floatingWhatsapp.link} />
          )}
        </DescriptionSection>
      </MainContainer>
      <FaqsPage />
    </>
  );
};

export default HelpCenter;
