import styled, { FlattenSimpleInterpolation, css } from "styled-components";
import { LinkResolver } from "app/components/link-resolver/link-resolver";

interface MenuItemProps {
  isOpen: boolean;
}
export const MenuItem = styled.div<MenuItemProps>`
  display: flex;
  flex-direction: column;
  box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
  gap: 10px;
`;

interface MenuTitleProps {
  isOpen: boolean;
}

export const MenuTitle = styled.div<MenuTitleProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 64px 24px 24px;
  box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
  color: ${({ theme }) => theme.primaryColor["300"]};
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
`;

export const MenuTitleText = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.secondaryColor["200"]};
  overflow-wrap: break-word;
`;

const subItemVariantStyles = (theme: any, variant = "primary") => {
  const variants: { [key: string]: FlattenSimpleInterpolation } = {
    primary: css`
      color: inherit;
    `,
    secondary: css`
      color: ${theme.primaryColor["300"]};
      font-weight: 600;
      display: flex;
      gap: 8px;
      align-items: center;
    `
  };

  return variants[variant];
};

export const SubItem = styled(LinkResolver)`
  padding: 24px 64px 24px 56px;
  color: inherit;
  text-decoration: inherit;
  overflow-wrap: break-word;
  ${({ theme, variant }) => subItemVariantStyles(theme, variant)}
  box-shadow: 0px -1px 0px 0px #F0F0F0 inset;
`;
